@import 'src/styles/onboarding_theme.scss';
@import '../../styles/variables';

.step-subtitle {
  color: $primary;
  font-weight: 700 !important;
  text-align: center;
}

#kyc-step-title {
  color: $secondary;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.kyc-step-description {
  white-space: pre-wrap;
  text-align: center;
}

.MuiStep-root {
  flex: 1;
  display: flex;
  flex-direction: column;

  &.centered-step {
    align-items: center;
    justify-content: center;
  }
}

.middle-content {
  min-height: auto;
  margin-top: 2rem !important;
}

.basic-button {
  background-color: $primary !important;

  &:disabled {
    color: #a8a8a8 !important;
  }
}

.card-content {
  text-align: center;
  margin: 10px !important;
}

.kyc-qr {
  width: 15rem;
  height: 15rem;
  margin: 2rem auto 1rem;
}

.qr-box {
  display: flex;
  flex-direction: column !important;
  background-color: #4760FF1A;
  border-radius: 30px;
  padding: 1rem;
  align-items: center;

  .qr-info {
    text-align: center;
  }
}

.gbg-box {
  background-color: #efefef;
  border-radius: 30px;
  padding: 1rem;
}

.privacy-link {
  cursor: pointer;
  text-decoration: none;
  color: $primary;
  font-weight: bold;
}

.start_verification_question {
  text-align: center !important;
}

.start_verification_question_answers {
  display: block !important;
  text-align: center;
}

.shareholder-operations {
  text-align: center;

  .add-shareholder-btn {
    margin-bottom: 1rem;
  }
}

.terms-and-conditions-box {
  background-color: #efefef;
  padding: 1rem;
  border-radius: 30px;
  margin-top: 1rem;
}

.initiate-kyc-card {
  max-width: 50rem;
  margin: auto auto;
  min-height: calc(100vh - 15rem);

  .initiate-kyc-btn {
    width: fit-content;
    align-self: center;
    margin-top: 2rem !important;
  }
}

@media screen and (max-width: $small-screen) {
  .initiate-kyc-card {
    min-height: calc(100vh - 19rem);
  }
}

.file-upload-box {
  max-width: 32rem;
}

#action-button {
  padding: 0.5rem 5rem;
}
