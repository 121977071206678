@import 'src/styles/onboarding_theme.scss';
.steps-container {
  margin: 1.5rem 0;
}

.verification-step-item {
  display: flex;
}

.verification-step {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: #4760FF1A;
  padding: 1.5rem;
  min-height: 15rem;
  border-radius: 30px;

  .step-title {
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.verification-description {
  text-align: center;
}

.flex-center {
  display: flex;
  justify-content: center;
}
