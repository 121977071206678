@import 'src/styles/onboarding_theme.scss';

.message-card {
  max-width: 50rem;
  margin: auto;

  #title {
    color: $secondary;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

}

.expired-link-message {
  margin-bottom: 20px;
  padding: 1rem;
}
