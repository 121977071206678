@import 'src/styles/onboarding_theme.scss';

#aml-submitted-page {
  align-items: center;
  padding: 2rem;
  .title {
    text-align: center;
    white-space: pre-wrap;
    font-weight: 700;
    color: #ffffff;
  }

  .description {
    text-align: center;
    white-space: pre-wrap;
    font-weight: 700;
    color: #ffffff;
    margin-top: 5rem;
    margin-bottom: 3rem;
  }

  .aml-btn {
    margin-bottom: 2rem;
  }
}

#aml-submitted-card {
  background-color: $primary;
}
