.date_of_incorporation {
    width: 100%;
}

.legal-entity-next-button {
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
}
.disclaimer{
    font-size: 13px;
}