@import 'src/styles/onboarding_theme.scss';
@import '../../styles/variables';

.footer {
  justify-content: space-between;
  padding: 0 5rem 1rem;
  flex-wrap: wrap;
  a {
    text-decoration: none;
    color: $secondary;
  }

  &.centered {
    border-top: 1px solid #e6e6e6;
    align-items: center;
    padding-top: 0.5rem;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: $small-screen) {
 .footer {
   justify-content: center;
   padding: 0 1rem;

   .rights-reserved {
     padding: 1rem 0;
   }
 }
}
