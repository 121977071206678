$primary: #4760FF;
$secondary: #002149;
$cards-background: white;
$second-font-color: white;

$shade-of-red: #cc001a;
$side-bar-items-hover: #53709c;
$main-page-background: #ebf2f2;

$boxx-yellow: #FCF55F;
$boxx-gray: rgb(240, 240, 240);

$boxx-gradient: linear-gradient(#4760FF, #556cff);
$boxx-gradientReversed: linear-gradient(#3B115D, #1C082E);
$base-color: #32be8f;
$second-color: #ebf2f2;
