@import 'src/styles/onboarding_theme.scss';

.radioButtonCheckedIcon {
  color: $primary;
  background: transparent;
}

.radioButtonLabel {
  width: 150px
}

.aml-stepper {
  justify-content: center;
  .MuiStep-root {
    flex: unset;
    width: fit-content;
  }
  div > button > span > span > svg {
    font-size: 2rem;

  }
}

.aml-procedure-card {
  min-height: calc(100vh - 15rem);
  display: flex;
  flex-direction: column;
}

.aml-question-wrapper {
  //justify-content: space-between;
  flex: 1;
}

.actions-box {
  display: flex;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.small-button {
  padding: 0.25rem 1.5rem !important;
}
