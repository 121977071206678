@import 'src/styles/onboarding_theme.scss';

.file-uploader {
  label {
    border: 0;
    border-radius: 10px;
    background-color: $primary;
    color: #fff;
    max-width: unset;
    min-width: unset;
  }

  label > div > span {
    color: #fff !important;
    span {
      text-decoration: none;
    }
  }

  label {
    svg {
      path {
        fill: #fff;
      }
    }
  }

  .selected-files-list {
    font-size: 12px;
    margin: 5px 0 0 10px;
  }
}
