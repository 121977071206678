@import 'src/styles/onboarding_theme.scss';
.aml-verification-box {
  align-items: center;
  white-space: pre-wrap;
  text-align: center;

  .next-btn {
    margin-top: 1rem;
  }
}
