@import 'src/styles/onboarding_theme.scss';

.read-only-field {
  background-color: $cards-background;
}

.proof-of-address-field {
  background-color: white;
}

.upload-button {
  background-color: $primary !important;
  height: 53px;
}

.prohibited-country {
  font-size: 13px;
  color: $shade-of-red;
}

.prohibited-country-wrapper {
  background-color: $shade-of-red;
}

.date-picker {
  width: 100%;
}
