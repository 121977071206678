@import 'src/styles/onboarding_theme.scss';
@import "../../../styles/variables";

.main-page {
  background-image: url($background-image);
  background-repeat: no-repeat;
  background-color: $main-page-background;
  background-size: 100%;
  height: 100vh;
  overflow-y: auto;
  background-position-x: center;
  background-position-y: center;

  .main-page-content {
    padding: 5rem;
    min-height: calc(100vh - 5rem);
  }

  @media screen and (max-width: $small-screen) {
    .main-page-content {
      padding: 6rem 0 0;
      min-height: unset;

    }
  }

  #step-title {
    color: $secondary;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 1rem;
    white-space: pre-wrap;
  }

}

@media screen and (max-width: $small-screen) {
  .main-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

