@import 'src/styles/onboarding_theme.scss';
@import "styles/variables";

.question-icon {
  color: $primary;
}

.question-title {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.mandatory-file-upload {
  color: $primary;
  font-weight: bold !important;
}

.question-order {
  background-color: $primary;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 0.5rem;
}


.footer-content {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: $small-screen) {
  .question-order {
    display: none;
  }
}
